const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

export const facebookPixel = {
  FB_PIXEL_ID,
  pageview: () => {
    // @ts-ignore
    if (window.fbq == null) {
      return
    }
    // @ts-ignore
    window.fbq("track", "PageView")
  },

  // https://developers.facebook.com/docs/facebook-pixel/advanced/
  event: (name: string, options = {}) => {
    // @ts-ignore
    if (window.fbq == null) {
      return
    }
    // @ts-ignore
    window.fbq("track", name, options)
  },
}
