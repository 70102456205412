import useTranslation from "next-translate/useTranslation"
import React from "react"

export default function useTranslateFunction() {
  const { t, lang } = useTranslation("translation")

  return {
    t: (key: string, options?: any, more?: any) => t(key, options, more),
    tWithComponents: (key: string, components: React.ReactNode[]) => (
      <CustomTrans i18nKey={key} components={components} />
    ),
    lang,
  }
}

export const CustomTrans = ({ i18nKey, components = [] }) => {
  const { t } = useTranslation("translation")

  const translation = t(i18nKey)

  const renderNestedComponents = (text, components) => {
    const regex = /<(\d+)>(.*?)<\/\1>/g
    let match
    let lastIndex = 0
    const result = []

    while ((match = regex.exec(text)) !== null) {
      const index = parseInt(match[1], 10)
      const beforeText = text.substring(lastIndex, match.index)
      if (beforeText) {
        result.push(beforeText)
      }
      const component = React.cloneElement(
        components[index],
        {},
        renderNestedComponents(match[2], components)
      )
      result.push(component)
      lastIndex = regex.lastIndex
    }

    if (lastIndex < text.length) {
      result.push(text.substring(lastIndex))
    }

    return result
  }

  return <>{renderNestedComponents(translation, components)}</>
}
