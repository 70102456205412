// CSS class to hide elements if you're in the mobile app: hide-if-mobile-app

import { useEffect, useState } from "react"

enum PlatformOS {
  IOS = "ios",
  ANDROID = "android",
}

declare global {
  interface Window {
    IS_MOBILE_APP: boolean
    PLATFORM_OS: PlatformOS
    CONFIRMAFY_APP_VERSION: undefined | string
    ReactNativeWebView: {
      postMessage: (message: string) => void
    }
  }
}

// Use this hook if you're getting hydration errors
export function useMobileAppHelper() {
  const [isMobileApp, setIsMobileApp] = useState(false)
  const [platformOS, setPlatformOS] = useState("")
  const [confirmafyAppVersion, setConfirmafyAppVersion] = useState<
    null | string | undefined
  >(null)

  useEffect(() => {
    const { isMobileApp, platformOS, confirmafyAppVersion } = mobileAppHelper()

    setIsMobileApp(isMobileApp)
    setPlatformOS(platformOS)
    setConfirmafyAppVersion(confirmafyAppVersion)
  }, [])

  return { isMobileApp, platformOS, confirmafyAppVersion }
}

function mobileAppHelper() {
  if (
    process.env.NEXT_PUBLIC_MOBILE === "true" &&
    typeof window !== "undefined"
  ) {
    // Running in dev mode as if it's the mobile app
    window.document.body.classList.add("is-mobile-app")
    window.document.body.classList.add("platform-android")

    return { isMobileApp: true, platformOS: "ios", confirmafyAppVersion: null }
  }

  if (typeof window === "undefined") {
    return { isMobileApp: false, platformOS: "", confirmafyAppVersion: null }
  }

  // IS_MOBILE_APP is injected by the webview on the mobile app
  return {
    isMobileApp: window.IS_MOBILE_APP,
    platformOS: window.PLATFORM_OS,
    confirmafyAppVersion: window.CONFIRMAFY_APP_VERSION,
  }
}

export default {
  mobileAppHelper,
  connectGoogleCalendar: () => {
    window.ReactNativeWebView.postMessage("CONNECT_GOOGLE_CALENDAR")
  },
  missingGoogleCalendarPermissions: () => {
    window.ReactNativeWebView.postMessage("MISSING_GOOGLE_CALENDAR_PERMISSIONS")
  },
  signOut: () => {
    window.ReactNativeWebView.postMessage("SIGN_OUT")
  },
  openGoogleCalendarApp: () => {
    window.ReactNativeWebView.postMessage("OPEN_GOOGLE_CALENDAR_APP")
  },
  openLink: (url: string) => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "OPEN_LINK", url })
    )
  },
  askForPushNotificationsPermission: () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "ASK_FOR_PUSH_NOTIFICATIONS_PERMISSION" })
    )
  },
  importContacts: (multiple: boolean) => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "IMPORT_CONTACTS", multiple: multiple })
    )
  },
}
