import es from "@shopify/polaris/locales/es.json"
import { AppProvider } from "@shopify/polaris"
import { PromptProvider } from "hooks/usePrompt"
import "@shopify/polaris/build/esm/styles.css"

export default function AppWithPolarisWrapper({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <AppProvider i18n={es}>
      <PromptProvider>{children}</PromptProvider>
    </AppProvider>
  )
}
