import mobileApp from "./mobileApp"
import posthog from "posthog-js"

export const mixpanel = {
  track: (event_name: string, props?: any) => {
    try {
      if ((window as any).mixpanel) {
        if (mobileApp.mobileAppHelper().isMobileApp) {
          ;(window as any).mixpanel.register({
            isFromMobileApp: true,
          })
        }

        ;(window as any).mixpanel.track(event_name, props)
      }

      if (event_name === "Page view") {
        posthog.capture("$pageview")
      }

      if (event_name !== "Page view") {
        posthog.capture(event_name, {
          ...props,
          isFromMobileApp: mobileApp.mobileAppHelper().isMobileApp,
        })
      }
    } catch (e) {
      console.log(e)
    }
  },
  identify: (
    id: string,
    postHogUserProperties: any,
    postHogSetOnceUserProperties: any
  ) => {
    try {
      if ((window as any).mixpanel) {
        ;(window as any).mixpanel.identify(id)
      }

      posthog.identify(id, postHogUserProperties, postHogSetOnceUserProperties)
    } catch (e) {
      console.log(e)
    }
  },
  people: {
    set: (props: any) => {
      try {
        if ((window as any).mixpanel) {
          ;(window as any).mixpanel.people.set(props)
        }
      } catch (e) {
        console.log(e)
      }
    },
    set_once: (props: any) => {
      try {
        if ((window as any).mixpanel) {
          ;(window as any).mixpanel.people.set_once(props)
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
