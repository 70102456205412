export const mockUser = {
  startMockingUser: async (email: string, id: string) => {
    localStorage.setItem("mocking_user_email", email)
    localStorage.setItem("mocking_user_id", id)
    window.location.href = "/app"
  },
  stopMockingUser: async () => {
    const idBeingMocked = localStorage.getItem("mocking_user_id")
    localStorage.removeItem("mocking_user_email")
    localStorage.removeItem("mocking_user_id")
    window.location.href = `/app/diagnostic?id=${idBeingMocked}`
  },
  getUserBeingMockedEmail: () => {
    return localStorage.getItem("mocking_user_email")
  },
}
